<template>
  <div class="page-wrapper">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>


    <!--Main Slider Start-->
    <section class="clearfix" id="home">

      <div class="slider">

        <!-- /.image-layer -->

        <div class="container">
          <div class="row ">
            <div class="col-xl-12">
              <div class="description">
                <h2><span class="secondary-color">Discount</span>ed motor insurance,  <br> <span class="secondary-color">for people who drive less.</span></h2>

                <p >If you don’t drive much, have more than one vehicle, or work  <br/>
                  from home you could qualify for big discounts on your car insurance. </p>

              </div>
            </div>
          </div>

          <section class="quotation-section" >

            <form method="post" action="#" v-on:submit.prevent="validateQuotationForm">
              <div class="container">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <h5>See if you qualify </h5>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.type.$error }">
                      Type of car
                    </label>

                    <v-select v-model.trim="$v.quotation.type.$model"   @input="updateCarBrand"    label="make" :options="car_brands"></v-select>

                    <div class="text-danger" v-if="!$v.quotation.type.required && $v.quotation.type.$dirty">Type of car is required</div>

                  </div>
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.model.$error }">
                      Type of vehicle model
                    </label>


                    <v-select v-model.trim="$v.quotation.model.$model"    label="model" :options="car_models"></v-select>

                    <div class="text-danger" v-if="!$v.quotation.model.required && $v.quotation.model.$dirty">Vehicle model is required</div>

                  </div>
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.year.$error }">
                      Year of manufacture
                    </label>

                    <v-select v-model.trim="$v.quotation.year.$model"     :options="years"></v-select>

                    <div class="text-danger" v-if="!$v.quotation.year.required && $v.quotation.year.$dirty">YoM is required</div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.estimate.$error }">
                      Value of car
                    </label> <br/>

                    <input v-model.trim="$v.quotation.estimate.$model"  class="form-control" type="text" />

                    <div class="text-danger" v-if="!$v.quotation.estimate.required">Value of car is required</div>
                  </div>

                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.registrationNo.$error }">
                      Vehicle registration Number
                    </label>
                    <input v-model.trim="$v.quotation.registrationNo.$model"  class="form-control" type="text" />
                    <div class="text-danger" v-if="!$v.quotation.registrationNo.required && $v.quotation.registrationNo.$dirty">Registration number is required</div>
                  </div>
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.mileage.$error }">
                      Kilometres Covered in a Year
                    </label>

                    <select class="form-control" v-model.trim="$v.quotation.mileage.$model" v-for="">
                      <option value="1000"> 0 - 5000</option>
                      <option value="5000">5000 - 10,000</option>
                      <option value="10000">Above 10,000</option>
                    </select>

                    <div class="text-danger" v-if="!$v.quotation.mileage.required && $v.quotation.mileage.$dirty">Mileage is required</div>

                  </div>


                </div>
                <div class="row">
                  <div class="col-md-4 offset-md-4">
                    <button  type="submit" class="btn btn-block btn-lg"> Get Quote </button>
                  </div>
                </div>
              </div>
            </form>


          </section>
        </div>
      </div>

    </section>
    <!--Main Slider End-->


    <section class="services-three" id="insurance">
      <div class="container">
        <div class="services-three__inner">

          <div class="row">
            <!--Services Three Single Start-->
            <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="100ms">
              <div class="services-three__single">
                <div class="section-title text-left">

                  <h2 class="section-title__title">Who qualifies to Switch?</h2>

                </div>
              </div>
            </div>
            <!--Services Three Single End-->
            <!--Services Three Single Start-->
            <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="200ms">
              <div class="services-three__single">
                <div class="services-three__img">
                  <img src="assets/images/nairobi_city.jpg" alt="">
                  <div class="services-three__content">
                    <h3 class="services-three__title">You live in the city <br/> and don’t regularly <br/> use your car
                      </h3>
                  </div>
                </div>
              </div>
            </div>
            <!--Services Three Single End-->
            <!--Services Three Single Start-->
            <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="300ms">
              <div class="services-three__single">
                <div class="services-three__img">
                  <img src="assets/images/drive_to_work.jpg" alt="">
                  <div class="services-three__content">
                    <h3 class="services-three__title">You’re reducing the <br/> number of days you <br/> drive to work </h3>
                  </div>
                </div>
              </div>
            </div>
            <!--Services Three Single End-->
            <!--Services Three Single Start-->
            <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="400ms">
              <div class="services-three__single">
                <div class="services-three__img">
                  <img src="assets/images/weekends.jpg" alt="">
                  <div class="services-three__content">
                    <h3 class="services-three__title">You only drive <br/> your car on <br/> weekends</h3>
                  </div>
                </div>
              </div>
            </div>
            <!--Services Three Single End-->
            <!--Services Three Single Start-->
            <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="500ms">
              <div class="services-three__single">
                <div class="services-three__img">
                  <img src="assets/images/second_car.jpg" alt="">
                  <div class="services-three__content">
                    <h3 class="services-three__title">You’ve bought a <br/>second car</h3>
                  </div>
                </div>
              </div>
            </div>
            <!--Services Three Single End-->
            <!--Services Three Single Start-->
            <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="600ms">
              <div class="services-three__single">
                <div class="services-three__img">
                  <img src="assets/images/speedometer.jpg" alt="">
                  <div class="services-three__content">
                    <h3 class="services-three__title">You regularly drive <br/>under 10,000Km a year</h3>
                  </div>
                </div>
              </div>
            </div>
            <!--Services Three Single End-->
            <!--Services Three Single Start-->
            <div class="col-xl-6 col-lg-7 col-md-9 wow fadeInUp" data-wow-delay="700ms">
              <div class="services-three__single">
                <div class="services-three__get-quote">
                  <p class="services-three__get-quote-sub-title">Getting a quote couldn’t be easier</p>
                  <h3 class="services-three__get-quote-title">You can generate your quote and save upto 10%.</h3>
                  <a style="border-radius: 0px" href="#" class="thm-btn services-three__get-quote-btn">Get a
                    Quote</a>
                </div>
              </div>
            </div>
            <!--Services Three Single End-->
          </div>
        </div>
      </div>
    </section>

    <section class="benefits">
      <div class="benefits-bg" style="background-image: url(assets/images/benefits.png);"></div>
      <div class="benefits-bg-2" style="background-image: url(assets/images/backgrounds/benefits-bg-2.jpg);">
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xl-6">
            <div class="benefits__left">
              <div class="section-title text-left">

                <h2 class="section-title__title">How to qualify for our Low-Mileage Car Insurance</h2>
              </div>
              <div class="benefits__point-box">
                <ul class="list-unstyled benefits__point">
                  <li>

                    <div class="icon">
                      <i class="fa fa-check"></i>
                    </div>
                    <div class="text">
                      <p>Calculate Your Current Annual Mileage</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fa fa-check"></i>
                    </div>
                    <div class="text">
                      <p>Reduce Your Mileage, if Possible</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fa fa-check"></i>
                    </div>
                    <div class="text">
                      <p> See if you qualify</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fa fa-check"></i>
                    </div>
                    <div class="text">
                      <p>Plug in and save</p>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="download">

      <div class="download-shape-1 float-bob-y">
        <img src="assets/images/shapes/download-shape-1.png" alt="">
      </div>
      <div class="download-shape-2 float-bob-x">
        <img src="assets/images/shapes/download-shape-2.png" alt="">
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-6">
            <div class="download__left">
              <p class="download__sub-title">Get our application free now!</p>
              <h3 class="download__title">Download our application</h3>
              <div class="download__apps">
                <div class="download__app-one">
                  <a href="#">
                    <i class="fa fa-play"></i>
                    <p> <span>Available on</span> <br> Google Play</p>
                  </a>
                </div>
                <div class="download__app-one download__app-one--two">
                  <a href="#">
                    <i class="fab fa-apple"></i>
                    <p> <span>get it on</span> <br> App Store</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-lg-6">
            <div class="download__right">
              <div class="download__img wow slideInRight" data-wow-delay="100ms"
                   data-wow-duration="2500ms">
                <img src="assets/images/download_app.png" alt="">

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <modal name="pricing-preview" height="350">
      <div class="pricing-header">
        <h4>Insurance Summary</h4>
      </div>

      <div class="pricing-body">
        <div class="row">
          <div class="col-md-12">
            <table class="table table-bordered">
              <tr >
                <th style="padding: 10px;">
                  Car
                </th>
                <td>
                      Mercedes Benz
                </td>
              </tr>
              <tr>
                <th style="padding: 10px;">
                  Estimated Value
                </th>
                <td>
                    KES. 21,000,0000
                </td>
              </tr>
              <tr>
                <th style="padding: 10px;">
                  12 Months Premium Amount
                </th>
                <td>
                  KES. 680,000
                </td>
              </tr>
              <tr>
                <th style="padding: 10px;">
                  Discount
                </th>
                <td>
                  KES. 80,000
                </td>
              </tr>
            </table>

            <div>
              <button type="button" class="btn btn-lg btn-block" @click="navigateToTermsAndConditions"> Submit </button>
            </div>
          </div>
        </div>
      </div>




    </modal>



  </div>
</template>

<script>

 import { mapActions } from "vuex";
 import Loading from 'vue-loading-overlay';
 import 'vue-loading-overlay/dist/vue-loading.css';
 import { required} from 'vuelidate/lib/validators'

 import CurrencyInput from './CurrencyInput';


export default {
  name: 'home',
  data () {
    return {
      isLoading: false,
      fullPage: true,
      value: null,
      options: ['list', 'of', 'options'],
      car_brands:[],
      car_models:[],
      mileage:"",

      quotation:{
        type:"",
        model:"",
        year:"",
        estimate:"",
        mileage:"",
        registrationNo:""
      },
      mileage_ranges:["Less than 5,000","5,000 - 10,000","10,000+"]
    };
  },
  mounted(){
    this.getVehicleMakesData();
  },
  validations: {
    quotation:{
      type:{required},
      model:{required},
      year:{required},
      estimate:{required},
      mileage:{required},
      registrationNo:{required}
    },
  },
  computed:{
    years(){
      let carManufactureYears = [];

      for(let i = 2022; i >= 2000; i--){

        carManufactureYears.push(i);
      }


      return carManufactureYears;
    }
  },
  components: { Loading,CurrencyInput },
  methods:{

    ...mapActions(["getVehicleMakes","getVehicleModels","getQuote"]),

    updateEstimate(){
      this.quotation.estimate = this.$refs.estimate.value;

      console.log(this.quotation.estimate);
    },
    getVehicleMakesData(){
          let self = this;

          this.getVehicleMakes().then((vehicles) => {
             self.car_brands = vehicles.data;

          }).catch(error => {
               console.log(error);
          })
    },

    getVehicleModelData(vehicleId){
      let self = this;

      this.isLoading = true;

      this.getVehicleModels({id: vehicleId}).then((vehicles) => {
        self.isLoading = false;
        self.car_models = vehicles.data;


        console.log(vehicles);
      }).catch(error => {
        self.isLoading = false;
        console.log(error);
      })
    },

    updateCarBrand(type){
      this.getVehicleModelData(type._id);
    },

    validateQuotationForm(){

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.checkQuotation()
      }
    },
    checkQuotation(){
      let self = this;

      let quoteData = {
        modelId: this.quotation.model._id,
        sumInsured:this.quotation.estimate,
        mileage:this.quotation.mileage,
        yearOfManufacture:this.quotation.year,
        vehicleRegistrationNumber:this.quotation.registrationNo,
        insuranceEntity:"TAKAFUL"
      };

      this.isLoading = true;


      this.getQuote(quoteData).then((quote) => {
        self.isLoading = false;



        self.$toast.open({
          message: quote.message,
          type: 'success',
          duration:5000,
          position:"top-right"
        });

        localStorage.setItem("quote",JSON.stringify(
                {
                  quoteId: quote.data._id,
                  modelId: quote.data.modelId,
                  traditionalPremium: quote.data.traditionalPremium,
                  sumInsured: quote.data.sumInsured,
                  make:self.quotation.type.make,
                  model:self.quotation.model.model,
                  yearOfManufacture:quote.data.yearOfManufacture,
                  vehicleRegistrationNumber:self.quotation.registrationNo,
                  distancePremium: quote.data.distancePremium
                }
                ));

        self.$router.push('/quote');

      }).catch(error => {
        self.isLoading = false;
        console.log(error);
      })


    },
    navigateToTermsAndConditions(){
      this.$modal.hide('pricing-preview');

      this.$router.push('/quote')
    }
  }
}
</script>


<style>
  label{
    font-family: 'Nunito Sans', sans-serif;
  }

</style>



